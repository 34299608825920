import React from 'react';

const goToTC = () => {
    try{
        window.location.href = 'tiendacryptoqa://' + encodeURIComponent('?address=asdfasdfasdfasdf');
      }catch(err){
        alert(err);
      }
}

const Test = () => {
    return (
      <div>
        <button onClick={() => goToTC()}></button>
      </div>
    );
  };

  
export default Test;